import {Page} from "../../components/PrivateRoutePage"
import React, {ChangeEvent, useState} from "react"
import {Layout} from "../../components/Layout"
import {Link, Trans, useTranslation} from "gatsby-plugin-react-i18next"
import {ExternalLink, ResponsiveContainer, SectionPreTitle, SectionTitle} from "../../components/Shared"
import {TextCenter, TextCenteredGrid} from "../diy/components/Shared"
import recalboxRGBJamma from "../../data/images/rgbjamma/jumbo.jpg"
import {Box, Button, Grid, TextField} from "@material-ui/core"
import styled from "@emotion/styled"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import YouTube from "react-youtube"
import {HomePage} from "../../pages"
import {AllImagesComponent} from "../download/components/AllImagesComponent"
import {DownloadBoardsYaml, RecalboxChannelsYaml} from "../../../graphql-types"

const TopMargedGrid = styled(Grid)({
    marginTop: "2em"
})

const RGBJammaTeasing = (props: {
    pageContext: { channel: RecalboxChannelsYaml, boards: DownloadBoardsYaml[] }
}) => {
    console.log("page context")
    console.log(props.pageContext)

    const {channel, boards} = props.pageContext
    const {t, i18n} = useTranslation()

    return <Layout
        title={"Recalbox RGB JAMMA"}
        description={t("rgbjamma^Recalbox in your JAMMA arcade machine!")}
        imageUrl={recalboxRGBJamma}>
        <ResponsiveContainer>
            <TextCenter>
                <SectionTitle>
                    Recalbox RGB JAMMA
                </SectionTitle>
                <SectionPreTitle>
                    {t("rgbjamma^Recalbox in your JAMMA arcade machine!")}
                </SectionPreTitle>
            </TextCenter>
            <TopMargedGrid container spacing={2}>
                <TextCenteredGrid item xs={12}>
                    <YouTube css={{width: "100%", height:"40vh"}} videoId={"uSR1MLym9_E"}/>
                </TextCenteredGrid>
                <Grid item xs={12}>
                    <SectionTitle>
                        {t("rgbjamma^It's back in stock!")}
                    </SectionTitle>
                </Grid>
                <Grid item xs={12}>
                    <TransMdTypo>
                        rgbjamma^Recalbox RGB is now available from the Official Store! You can order it in two versions: \n- the complete kit, with case, screen and fan\n- the board alone.
                    </TransMdTypo>
                    <Box css={{marginTop: "1em"}}>
                        <Button variant={"outlined"} color={"secondary"}
                                href={`https://shop.recalbox.com/collections/recalbox-rgb-jamma`}>{t("rgbjamma^Order yours now")}</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box css={{marginTop: "1em"}}>
                        <AllImagesComponent channel={channel} boards={boards} extraMessage={
                            <TransMdTypo>rgbjamma^The stable version of Recalbox is compatible with Recalbox RGB JAMMA.
                            </TransMdTypo>}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <SectionTitle>
                        Documentation
                    </SectionTitle>
                    <TransMdTypo>
                        rgbjamma^Full documentation for Recalbox RGB JAMMA can be found on the project wiki.
                    </TransMdTypo>
                    <Box css={{marginTop: "1em"}}>
                        <Button variant={"outlined"} color={"secondary"}
                                href={`https://wiki.recalbox.com/${i18n.language === "fr" ? "fr" : "en"}/hardware-compatibility/recalbox-rgb-jamma-new`}>{t("rgbjamma^Documentation du wiki")}</Button>
                    </Box>
                </Grid>
            </TopMargedGrid>

        </ResponsiveContainer>
    </Layout>
}

const getUrl = () => "/recalbox-rgb-jamma/"
export const RGBJammaTeasingPage: Page = {
    Page: RGBJammaTeasing,
    getUrl,
    parentPage: () => HomePage,
    breadCrumb: {name: "Recalbox RGB JAMMA", link: getUrl()}
}

export default RGBJammaTeasingPage.Page